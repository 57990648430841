import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DecimalNumber } from '@api/baseAPI/types';
import { MergedOpenBillOrderStatus } from '@api/dineIn/types';
import { CustomerOrderPriceDetail, ProductOrder, Promotion } from '@api/order/types';
import { Button, Stack, Table, TableBody, Typography } from '@components/common';
import { IconDiscount } from '@components/icons';
import { useCheckDineIn } from '@hooks/dineIn';
import { useProductPrice } from '@hooks/products';
import { useCommonStore } from '@hooks/storage';
import { Divider, Skeleton } from '@mui/material';
import { calcDecimal, formatCurrency } from '@utils/number';
import AdjustmentDetailLine from './AdjustmentDetailLine';
import ProductDetailLine from './ProductDetailLine';
import PromoLine from './PromoLine';
import PromotionSelection from './PromotionSelection';

interface Props {
  products: ProductOrder[];
  favoriteProductIds?: number[];
  priceDetail?: CustomerOrderPriceDetail;
  showDeliveryFee?: boolean;
  showDiscount?: boolean;
  isLoading?: boolean;
  error?: string;
  onRetry?: () => void;
  status?: MergedOpenBillOrderStatus;
  isDetail: boolean;
  adjustmentAmount?: DecimalNumber;
  showPromo?: boolean;
  appliedPromos?: Promotion[];
  point?: number;
  pointSnaphot?: number;
  totalPromoAmount?: string;
}
const OrderDetail = ({
  products = [],
  favoriteProductIds = [],
  priceDetail,
  isLoading,
  showDeliveryFee,
  showDiscount,
  onRetry,
  error,
  status,
  isDetail,
  adjustmentAmount,
  showPromo,
  appliedPromos,
  point,
  pointSnaphot,
}: Props) => {
  const { t } = useTranslation();
  const { isDineIn } = useCheckDineIn();
  const { storageState } = useCommonStore();

  const {
    subTotal = 'N/A',
    subTotalBeforeTax = 'N/A',
    serviceChargeAfterTax = 'N/A',
    taxAmount = 'N/A',
    deliveryFee = 'N/A',
    totalAmount = 'N/A',
    isTaxInclusive,
    creditUsage = 'N/A',
    totalAmountAfterCredit = 'N/A',
    onlinePlatformFee = '',
    roundingAmount = 'N/A',
    dineInFeeChargeToPurchaser,
    dineInPgFee,
    dineInPlatformFee,
    totalAmountFinal,
    serviceCharge = 'N/A',
    discountWithoutTax = 'N/A',
  } = priceDetail || {};

  const shouldShowCredit = useMemo(() => {
    if (creditUsage === 'N/A') {
      return false;
    }

    const parsedCreditUsage = calcDecimal(creditUsage);
    return parsedCreditUsage instanceof Decimal && parsedCreditUsage?.greaterThan(0);
  }, [creditUsage]);

  const showTotalAmountAfterCredit = useMemo(() => {
    if (totalAmountAfterCredit === 'N/A') {
      return false;
    }

    const parsedTotalAmountCredit = calcDecimal(totalAmountAfterCredit);
    return parsedTotalAmountCredit instanceof Decimal && parsedTotalAmountCredit?.greaterThan(0);
  }, [totalAmountAfterCredit]);

  const showServiceCharge = useMemo(() => {
    if (isTaxInclusive) {
      if (serviceChargeAfterTax === 'N/A') {
        return false;
      }

      if (serviceChargeAfterTax) {
        const parsedServiceChargeAfterTax = calcDecimal(serviceChargeAfterTax);
        if (parsedServiceChargeAfterTax instanceof Decimal) {
          return parsedServiceChargeAfterTax.toNumber() > 0;
        }
      }

      return false;
    }

    if (serviceCharge === 'N/A') {
      return false;
    }

    if (serviceCharge) {
      const parsedServiceCharge = calcDecimal(serviceCharge);
      if (parsedServiceCharge instanceof Decimal) {
        return parsedServiceCharge.toNumber() > 0;
      }
    }

    return false;
  }, [isTaxInclusive, serviceCharge, serviceChargeAfterTax]);

  const showRoundingAmount = useMemo(() => {
    if (roundingAmount === 'N/A') {
      return false;
    }
    const parsedRoundingAmount = calcDecimal(roundingAmount);
    return parsedRoundingAmount instanceof Decimal && parsedRoundingAmount?.greaterThan(0);
  }, [roundingAmount]);

  const showTax = useMemo(() => {
    if (taxAmount === 'N/A') {
      return false;
    }

    if (taxAmount) {
      const parsedTaxAmount = calcDecimal(taxAmount);
      if (parsedTaxAmount instanceof Decimal) {
        return parsedTaxAmount.toNumber() > 0;
      }
    }

    return false;
  }, [taxAmount]);

  const showOnlinePlatformFee = useMemo(() => {
    if (onlinePlatformFee === '') {
      return false;
    }

    if (onlinePlatformFee) {
      const parsedOnlinePlatformFee = calcDecimal(onlinePlatformFee);
      if (parsedOnlinePlatformFee instanceof Decimal) {
        return parsedOnlinePlatformFee.toNumber() > 0;
      }
    }

    return false;
  }, [onlinePlatformFee]);

  const isOnGoingOpenBill = useMemo(() => {
    return status === 'opened';
  }, [status]);

  const { label: subtotalPriceLabel } = useProductPrice(isTaxInclusive ? subTotal : subTotalBeforeTax);
  const { label: deliveryFeeLabel } = useProductPrice(showDeliveryFee ? deliveryFee : 0);
  const { label: serviceChargeLabel } = useProductPrice(isTaxInclusive ? serviceChargeAfterTax : serviceCharge);
  const { label: taxLabel } = useProductPrice(showTax ? taxAmount : 0);
  const { label: discountLabel, isFree: isDiscountFree } = useProductPrice(showDiscount ? discountWithoutTax : 0);
  const { label: creditUsageLabel } = useProductPrice(shouldShowCredit ? creditUsage : 0);
  const { label: totalAmountAfterCreditLabel } = useProductPrice(
    showTotalAmountAfterCredit ? totalAmountAfterCredit : 0
  );

  const total = useMemo(() => {
    if (isDetail) {
      return isOnGoingOpenBill ? totalAmount : totalAmountAfterCredit;
    }

    return isDineIn ? totalAmountFinal : totalAmount;
  }, [isDetail, isOnGoingOpenBill, isDineIn, totalAmount, totalAmountAfterCredit, totalAmountFinal]);

  const { label: totalAmountLabel } = useProductPrice(total);

  const { label: onlinePlatformFeeLabel } = useProductPrice(onlinePlatformFee);
  const { label: roundingAmountLabel } = useProductPrice(roundingAmount);

  const {
    label: adjustmentAmountLabel,
    isNegative: isNegativeAdjustmentAmountLabel,
    isFree: isFreeAdjustmentAmountLabel,
  } = useProductPrice(adjustmentAmount);

  const platformFee = useMemo(() => {
    const calcDineInPgFee = calcDecimal(dineInPgFee);
    const calcDineInPlatformFee = calcDecimal(dineInPlatformFee);
    if (calcDineInPgFee instanceof Decimal && calcDineInPlatformFee instanceof Decimal) {
      return calcDineInPgFee.add(calcDineInPlatformFee).toString();
    }
  }, [dineInPgFee, dineInPlatformFee]);

  const { label: platformFeeLabel } = useProductPrice(platformFee);

  const totalAmountGreaterThanTotalAmountAfterCredit = useMemo(() => {
    const parsedTotalAmount = calcDecimal(totalAmount);
    const parsedTotalAmountAfterCredit = calcDecimal(totalAmountAfterCredit);
    if (parsedTotalAmount instanceof Decimal && parsedTotalAmountAfterCredit instanceof Decimal) {
      if (parsedTotalAmountAfterCredit.equals(0)) {
        return false;
      }
      return parsedTotalAmount.greaterThan(parsedTotalAmountAfterCredit);
    }
  }, [totalAmount, totalAmountAfterCredit]);

  const isHasAdjustment = useMemo(
    () => !!(adjustmentAmountLabel && !isFreeAdjustmentAmountLabel),
    [adjustmentAmountLabel, isFreeAdjustmentAmountLabel]
  );

  const totalPromoAmount = useMemo(() => {
    const promoAmount = appliedPromos
      ?.filter((promo) => promo.promoRule.productCategoryIds.length === 0 && promo.promoRule.productIds.length === 0)
      ?.map((promo) => {
        const calcPromoAmount = calcDecimal(promo.amount);
        if (calcPromoAmount && calcPromoAmount instanceof Decimal) {
          calcPromoAmount.add(calcPromoAmount);
        }

        return calcPromoAmount;
      });
    return promoAmount?.toString();
  }, [appliedPromos]);

  const isHaveTotalPromoAmount = useMemo(() => {
    const calcTotalPromoAmount = calcDecimal(totalPromoAmount);
    return calcTotalPromoAmount instanceof Decimal && calcTotalPromoAmount.greaterThan(0);
  }, [totalPromoAmount]);

  if (error) {
    return (
      <Stack spacing={'xs'}>
        <Typography variant={'bold'} color={'uiRedPrimary'} textAlign={'center'}>
          {error}
        </Typography>
        <Button variant={'contained'} color={'error'} onClick={onRetry}>
          {t('order.retry_get_price_button_label')}
        </Button>
      </Stack>
    );
  }

  return (
    <Stack spacing={'m'}>
      <Typography size={'hs'} variant={'medium'}>
        {t('order.order_detail_label')}
      </Typography>
      <Divider />

      <Stack spacing={'hm'}>
        <Table>
          <TableBody>
            {products.map((product, index) => (
              <ProductDetailLine key={index} product={product} isFavorite={favoriteProductIds.includes(product.id)} />
            ))}
          </TableBody>
        </Table>
        {products?.length === 0 && (
          <Typography size={'s'} color={'uiDarkSecondary'}>
            {t('order.no_product_available')}
          </Typography>
        )}
      </Stack>

      <Divider />

      {!isDetail && (
        <>
          <Typography size={'hs'} variant={'medium'} transform={'uppercase'}>
            {t('order.promo')}
          </Typography>
          <PromotionSelection appliedPromos={appliedPromos} />
          <Divider />
        </>
      )}

      <Stack spacing={'xxs'}>
        <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
          <Typography size={'s'} variant={'medium'} color={'uiDarkStain'}>
            {t('order.sub_total_label')}
          </Typography>
          <Typography size={'s'}>
            {isLoading ? <Skeleton variant={'text'} width={100} /> : subtotalPriceLabel}
          </Typography>
        </Stack>

        <AdjustmentDetailLine
          label={adjustmentAmountLabel}
          enable={isHasAdjustment}
          isNegative={isNegativeAdjustmentAmountLabel}
        />

        {showDeliveryFee && (
          <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
            <Typography size={'s'} variant={'medium'} color={'uiDarkStain'}>
              {t('order.delivery_fee_label')}
            </Typography>
            <Typography size={'s'}>
              {isLoading ? <Skeleton variant={'text'} width={100} /> : deliveryFeeLabel}
            </Typography>
          </Stack>
        )}

        {showServiceCharge && (
          <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
            <Typography size={'s'} color={'uiDarkSecondary'}>
              {t('order.service_charge_label')}
            </Typography>
            <Typography size={'s'}>
              {isLoading ? <Skeleton variant={'text'} width={100} /> : serviceChargeLabel}
            </Typography>
          </Stack>
        )}

        {showTax && (
          <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
            <Typography size={'s'} color={'uiDarkSecondary'}>
              {isTaxInclusive ? t('order.inclusive_tax_label') : t('order.exclusive_tax_label')}
            </Typography>
            <Typography color={isTaxInclusive ? 'uiDarkSecondary' : 'uiDarkPrimary'} size={'s'}>
              {isLoading ? <Skeleton variant={'text'} width={100} /> : taxLabel}
            </Typography>
          </Stack>
        )}

        {showOnlinePlatformFee && (
          <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
            <Typography size={'s'} color={'uiDarkSecondary'}>
              {t('order.online_platform_fee_label')}
            </Typography>
            <Typography size={'s'}>
              {isLoading ? <Skeleton variant={'text'} width={100} /> : onlinePlatformFeeLabel}
            </Typography>
          </Stack>
        )}

        {showRoundingAmount && (
          <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
            <Typography size={'s'} color={'uiDarkSecondary'}>
              {t('order.rounding_label')}
            </Typography>
            <Typography size={'s'}>
              {isLoading ? <Skeleton variant={'text'} width={100} /> : roundingAmountLabel}
            </Typography>
          </Stack>
        )}

        {dineInFeeChargeToPurchaser && !totalAmountGreaterThanTotalAmountAfterCredit && (
          <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
            <Typography size={'s'} color={'uiDarkSecondary'}>
              {t('dine_in.platform_fee_label')}
            </Typography>
            <Typography size={'s'}>
              {isLoading ? <Skeleton variant={'text'} width={100} /> : platformFeeLabel}
            </Typography>
          </Stack>
        )}
      </Stack>

      <Divider />
      {showDiscount && (
        <>
          <Stack alignItems={'flex-start'} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
            <Stack>
              <Stack spacing={'xxs'} direction={'row'} alignContent={'flex-end'}>
                <Typography size={'s'} variant={'medium'} color={'uiDarkStain'}>
                  {t('order.discount_label')}
                </Typography>
              </Stack>
            </Stack>
            <Typography color={'uiRedPrimary'} size={'s'}>
              {isDiscountFree ? discountLabel : `-${discountLabel}`}
            </Typography>
          </Stack>
          <Divider />
        </>
      )}

      {showPromo && (
        <>
          {appliedPromos?.map((promo) => (
            <PromoLine key={promo.id} name={promo.name} amount={promo.amount} />
          ))}
          <Divider />
        </>
      )}

      {shouldShowCredit ? (
        <>
          <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
            <Typography size={'s'}>{t('order.total_cost_label')}</Typography>
            <Typography size={'s'}>
              {isLoading ? <Skeleton variant={'text'} width={100} /> : totalAmountLabel}
            </Typography>
          </Stack>

          <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
            <Typography size={'xs'} variant={'medium'} color={'uiDarkTertiary'} transform={'uppercase'}>
              {t('order.credit_usage_amount_label')}
            </Typography>
            <Typography size={'xs'} variant={'medium'} color={'uiDarkTertiary'}>
              {isLoading ? <Skeleton variant={'text'} width={100} /> : `- ${creditUsageLabel}`}
            </Typography>
          </Stack>

          {dineInFeeChargeToPurchaser && totalAmountGreaterThanTotalAmountAfterCredit && (
            <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
              <Typography size={'s'} color={'uiDarkSecondary'}>
                {t('dine_in.platform_fee_label')}
              </Typography>
              <Typography size={'s'}>
                {isLoading ? <Skeleton variant={'text'} width={100} /> : platformFeeLabel}
              </Typography>
            </Stack>
          )}

          <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
            <Typography size={'s'} variant={'medium'} transform={'uppercase'}>
              {t('order.total_bill_label')}
            </Typography>
            <Typography size={'xl'} variant={'medium'}>
              {isLoading ? <Skeleton variant={'text'} width={100} /> : totalAmountAfterCreditLabel}
            </Typography>
          </Stack>
        </>
      ) : (
        <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
          <Typography size={'s'} variant={'medium'}>
            {t('order.total_bill_label')}
          </Typography>
          <Typography size={'xl'} variant={'medium'}>
            {isLoading ? <Skeleton variant={'text'} width={100} /> : totalAmountLabel}
          </Typography>
        </Stack>
      )}

      {isHaveTotalPromoAmount && (
        <Stack
          direction={'row'}
          paddingX={'m'}
          paddingY={'xs'}
          background={'backgroundNeutralSecondary'}
          borderRadius={'default'}
          columnGap={'s'}
          alignItems={'center'}
        >
          <IconDiscount width={16} height={16} />
          <Typography size={'s'} variant={'medium'} color={'backgroundPositive'}>
            {t('order.discount_announcement', {
              amount: formatCurrency(totalPromoAmount, storageState.currency),
            })}
          </Typography>
        </Stack>
      )}

      {!!point && (
        <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
          <Typography size={'s'}>{t('order.point_earned')}</Typography>
          <Typography size={'s'} variant={'medium'} color={'uiGreenPrimary'}>
            {isLoading ? <Skeleton variant={'text'} width={100} /> : `+${t('order.points', { count: point })}`}
          </Typography>
        </Stack>
      )}
      {!!pointSnaphot && (
        <Stack alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'}>
          <Typography size={'s'} color={'uiDarkSecondary'}>
            {t('order.current_point')}
          </Typography>
          <Typography size={'s'} color={'uiDarkSecondary'}>
            {isLoading ? <Skeleton variant={'text'} width={100} /> : t('order.points', { count: pointSnaphot })}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default OrderDetail;
