import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductOrder } from '@api/order/types';
import { Stack, SwipeableDrawer, Typography } from '@components/common';
import { useCommonStore } from '@hooks/storage';
import { formatCurrency } from '@utils/number';

interface Props {
  open?: boolean;
  onClose: () => void;
  onOpen: () => void;
  products?: ProductOrder[];
  totalDiscount: string;
}

const DiscountListDrawer: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { products = [], onClose, onOpen, open = false, totalDiscount } = props;
  const productsWithDiscount = products.filter((product) => Number(product.promoAmount) > 0);
  const { storageState } = useCommonStore();

  return (
    <SwipeableDrawer anchor={'bottom'} open={open} onClose={onClose} onOpen={onOpen} disableBackdropTransition>
      <Stack alignItems={'center'} justifyContent={'center'} paddingTop={'xs'}>
        <Stack borderRadius={'form'} width={23} height={4} background={'borderSubtle'} />
      </Stack>
      <Stack padding={'m'} spacing={'xxl'}>
        <Stack alignItems={'flex-start'} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
          <Stack>
            <Typography size={'hm'} variant={'medium'}>
              {t('order.discount_label')}
            </Typography>
            <Typography size={'hs'} color={'uiDarkSecondary'}>
              {t('order.discount_availability_period_label')}
            </Typography>
          </Stack>
          <Stack borderRadius={'form'} borderColor={'uiRedPrimary'} padding={'xxs'} borderWidth={'thin'}>
            <Typography color={'uiRedPrimary'} size={'hm'}>
              {`-${totalDiscount}`}
            </Typography>
          </Stack>
        </Stack>

        {productsWithDiscount.map((product, index) => (
          <Stack
            key={index}
            alignItems={'center'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
          >
            <Typography size={'s'} variant={'light'}>
              {product?.name}
            </Typography>
            <Typography size={'hm'} variant={'medium'}>
              {formatCurrency(product?.promoAmount, storageState.currency)}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </SwipeableDrawer>
  );
};

export default DiscountListDrawer;
