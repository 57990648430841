import Decimal from 'decimal.js';
import React, { useMemo } from 'react';
import { DecimalNumber } from '@api/baseAPI/types';
import { Stack, Typography } from '@components/common';
import Token from '@constants/token';
import { useProductPrice } from '@hooks/products';
import { useCommonStore } from '@hooks/storage';
import { StackProps } from '@mui/material';
import { calcDecimal, formatCurrency } from '@utils/number';

interface Props {
  price: DecimalNumber;
  discount?: DecimalNumber;
  justifyContent?: StackProps['justifyContent'];
  alignItems?: StackProps['alignItems'];
  direction?: StackProps['direction'];
  disabled?: boolean;
}
const ProductPrice: React.FC<Props> = (props) => {
  const { price = '0', discount, direction = 'row', alignItems = 'center', justifyContent, disabled } = props;

  const { label: priceLabel } = useProductPrice(price);
  const { storageState } = useCommonStore();

  const priceAfterDiscount = useMemo(() => {
    const parsedDiscount = calcDecimal(discount);
    const parsedPrice = calcDecimal(price);
    if (parsedDiscount instanceof Decimal && parsedPrice instanceof Decimal) {
      return parsedPrice.minus(parsedDiscount).toString();
    }
  }, [discount, price]);

  return (
    <Stack direction={direction} spacing={'xxs'} alignItems={alignItems} justifyContent={justifyContent}>
      {priceAfterDiscount ? (
        <>
          <Typography
            textDecoration={'line-through'}
            textDecorationColor={Token.color.uiRedPrimary}
            size={'hs'}
            color={'uiDarkSecondary'}
            align={'center'}
          >
            {priceLabel}
          </Typography>
          <Typography lineHeight={1.3} color={disabled ? 'uiDarkSecondary' : 'uiDarkPrimary'}>
            {formatCurrency(priceAfterDiscount, storageState.currency)}
          </Typography>
        </>
      ) : (
        <Typography color={disabled ? 'uiDarkSecondary' : 'uiDarkPrimary'}>{priceLabel}</Typography>
      )}
    </Stack>
  );
};

export default ProductPrice;
