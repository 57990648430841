import sumBy from 'lodash/sumBy';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionSetOption } from '@api/optionSets/types';
import { IconButton, Stack, Typography } from '@components/common';
import { ChevronIcon } from '@components/icons';
import { useCommonStore } from '@hooks/storage';
import { Collapse, Divider } from '@mui/material';
import { formatCurrency, toNumber } from '@utils/number';
import OptionButtonList from './OptionButtonList';

interface Props {
  onChange?: (value?: number[]) => void;
  value?: number[];
  optionTitle: string;
  options?: OptionSetOption[];
  minimumSelected?: number;
  maximumSelected?: number;
}
const ProductCustomOptionCollapse: FC<Props> = ({ options = [], optionTitle, ...props }) => {
  const { minimumSelected, maximumSelected, onChange } = props;
  const { storageState } = useCommonStore();

  const [collapse, setIsCollapse] = useState(true);

  const { t } = useTranslation();
  const value = useMemo(() => props?.value || [options?.[0]?.id], [options, props?.value]);

  const totalPrice = useMemo(
    () =>
      sumBy(
        value?.map((optionId) => options?.find((option) => option?.id === optionId)),
        (option) => toNumber(option?.price) ?? 0
      ),
    [options, value]
  );

  const toggleCollapse = () => setIsCollapse((prevValue) => !prevValue);
  const showPrice = !!totalPrice;

  const collapseDescription = useMemo(() => {
    if (!minimumSelected && !maximumSelected) {
      return '';
    }

    const min = minimumSelected || 0;
    const max = maximumSelected || options?.length;

    if (min === 0 && max !== 0) {
      return t('products.max_custom_option_select_title', { max });
    }

    if (min !== 0 && max === 0) {
      return t('products.min_custom_option_select_title', { min });
    }

    if (min !== max) {
      return t('products.min_max_custom_option_select_title', { min, max });
    }

    return t('products.default_number_custom_option_select_title', { count: min });
  }, [maximumSelected, minimumSelected, options?.length, t]);

  return (
    <Stack direction={'column'} spacing={'s'}>
      <Stack direction={'column'} spacing={'xxs'}>
        <Stack spacing={'xxs'} direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
          <Stack
            width={'100%'}
            overflow={'hidden'}
            direction={'row'}
            alignItems={'center'}
            spacing={'xxs'}
            flexWrap={'wrap'}
          >
            <Stack direction={'row'} columnGap={'s'} alignItems={'center'} flexWrap={'wrap'}>
              <Typography textOverflow={'ellipsis'} size={'hm'} variant={'bold'} lineHeight={'30px'}>
                {optionTitle}
              </Typography>
              {!!collapseDescription && (
                <Typography size={'hs'} variant={'medium'}>
                  {collapseDescription}
                </Typography>
              )}
            </Stack>
            {showPrice && (
              <Stack flexGrow={1}>
                <Typography size={'s'} variant={'light'}>
                  {`(+ ${formatCurrency(totalPrice, storageState.currency)})`}
                </Typography>
              </Stack>
            )}
          </Stack>

          <IconButton padding={0} onClick={toggleCollapse}>
            <Stack borderWidth={'bolder'} padding={'xxs'} borderColor={'borderSubtle'} borderRadius={'default'}>
              <ChevronIcon rotate={collapse ? 0 : -90} />
            </Stack>
          </IconButton>
        </Stack>
      </Stack>
      <Divider />

      <Collapse in={collapse}>
        <OptionButtonList
          minimumSelected={minimumSelected}
          maximumSelected={maximumSelected}
          value={value}
          onChange={onChange}
          options={options}
        />
      </Collapse>
    </Stack>
  );
};

export default ProductCustomOptionCollapse;
