import Decimal from 'decimal.js';
import { DecimalNumber } from '@api/baseAPI/types';
import {
  DEFAULT_CURRENCY_DECIMAL_SEPARATOR,
  DEFAULT_CURRENCY_THOUSANDS_SEPARATOR,
  DEFAULT_CURRENCY_UNIT,
} from '@constants/currency';
import { Currency } from '@storage/types';

/**
 * calcDecimal used to convert string/number to decimal, it will also remove trailing zeros.
 * For example:
 * calcDecimal('10.0') -> 10 (but in Decimal class type)
 * calcDecimal('10.0')?.toString() -> '10'
 */
export const calcDecimal = (value?: string | number | null) => {
  if (typeof value !== 'undefined' && value !== null) {
    try {
      return new Decimal(value);
    } catch (e) {
      if (e instanceof Error && /DecimalError/.test(e.message)) {
        if (value) {
          console.info('You can safely ignore this. This is just to know what value FE try to calcDecimal: ', value);
        }
        return undefined;
      }
    }
  }

  return value;
};

export const toNumber = (str = '0') => {
  const parsedStr = calcDecimal(str);
  if (parsedStr instanceof Decimal) {
    return parsedStr?.toNumber();
  }
};

export const toLocaleStringNumber = (amount = '0', locale = 'id-ID') => toNumber(amount)?.toLocaleString(locale);

export const formatCurrency = (price: DecimalNumber | Decimal | number = 0, currency?: Currency) => {
  const priceDecimal = price instanceof Decimal ? price : calcDecimal(price);
  if (!(priceDecimal instanceof Decimal)) {
    return price.toString();
  }
  const currencyUnit = currency?.currencyUnit ?? DEFAULT_CURRENCY_UNIT;
  const maximumFractionDigits = currency?.currencyPrecision ?? 2;
  const truncatedNumber = priceDecimal.toDecimalPlaces(maximumFractionDigits, Decimal.ROUND_DOWN).toNumber();

  let formattedNumber = Intl.NumberFormat('id-ID', {
    currency: 'IDR',
    maximumFractionDigits,
    minimumFractionDigits: !currency || currency.currencyStripInsignificantZeros ? 0 : maximumFractionDigits,
  }).format(truncatedNumber);

  const [wholePart, decimalPart] = formattedNumber.split(',');
  const currencyThousandsSeparator = currency?.currencyThousandsSeparator;
  const currencyDecimalSeparator = currency?.currencyDecimalSeparator ?? DEFAULT_CURRENCY_DECIMAL_SEPARATOR;
  let formattedWholePart = wholePart;

  // Format the whole part with correct thousand separator
  if (currencyThousandsSeparator && currencyThousandsSeparator !== DEFAULT_CURRENCY_THOUSANDS_SEPARATOR) {
    formattedWholePart = formattedWholePart.replace(/\./g, currencyThousandsSeparator);
  }

  // Combine parts with correct decimal separator
  formattedNumber = decimalPart ? `${formattedWholePart}${currencyDecimalSeparator}${decimalPart}` : formattedWholePart;
  return `${currencyUnit} ${formattedNumber}`;
};
