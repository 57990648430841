import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import SnackbarProvider from 'src/context/SnackbarProvider';
import { atomServiceWorker } from '@atoms/serviceWorker';
import { SUBDOMAIN_DEFAULT } from '@constants/auth';
import { FIREBASE_OPTIONS } from '@constants/firebase';
import { AppContainer } from '@containers/common';
import { CacheProvider } from '@emotion/react';
import { useFeatureToggle } from '@hooks/common';
import { useDetectRouteChanges } from '@hooks/track';
import { AppPropsWithLayout } from '@interfaces/common';
import { ResizeObserver as ResizeObserverPoly } from '@juggle/resize-observer';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { createEmotionCache } from '@utils/emotion';
import '@utils/i18n';
import { getBrandUrl } from '@utils/navigator';
import createTheme from '@utils/theme/createTheme';

if (typeof window !== 'undefined' && !window.ResizeObserver) {
  window.ResizeObserver = ResizeObserverPoly;
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const theme = createTheme();

function RunchiseApp({ Component, pageProps, emotionCache = clientSideEmotionCache }: AppPropsWithLayout) {
  const [_, setAtomServiceWorker] = useAtom(atomServiceWorker);
  const { REDIRECT_TO_NEW_SITE } = useFeatureToggle();
  useDetectRouteChanges();

  useEffect(() => {
    if (REDIRECT_TO_NEW_SITE) {
      const brandUrl = getBrandUrl();
      if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' && brandUrl.includes('ordernow.id')) {
        const newHref = window.location.href.replace('ordernow.id', 'orderfood.id');
        window.location.replace(newHref);
        return;
      }
      if (brandUrl.includes(SUBDOMAIN_DEFAULT)) {
        const searchParams = new URLSearchParams(new URL(window.location.href).search);
        const storeName = brandUrl.replace(`.${SUBDOMAIN_DEFAULT}`, '');
        // There is no dedicated new domain for staging so we use Vercel domain just for testing
        window.location.replace(`https://tummy-git-${storeName}-runchise-go.vercel.app?${searchParams.toString()}`);
        return;
      }
    }
  }, [REDIRECT_TO_NEW_SITE]);

  const registerServiceWorker = useCallback(async () => {
    /**
     * Googlebot doesn’t register service worker
     * We put try catch block to prevent sentry send error unhandled exception
     */
    try {
      if ('serviceWorker' in navigator) {
        const firebaseConfig = encodeURIComponent(JSON.stringify(FIREBASE_OPTIONS));
        const registration = await navigator.serviceWorker.register(
          `/firebase-messaging-and-pwa-sw.js?config=${firebaseConfig}`
        );
        setAtomServiceWorker((prevState) => ({
          ...prevState,
          serviceWorkerRegistration: registration,
        }));
      }
    } catch (error) {
      console.info('Failed to register service worker', error);
    }
  }, [setAtomServiceWorker]);

  useEffect(() => {
    registerServiceWorker();
  }, [registerServiceWorker]);

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <SnackbarProvider>
            <AppContainer Component={Component} pageProps={pageProps} />
          </SnackbarProvider>
        </StylesProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default RunchiseApp;
