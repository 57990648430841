import { sortBy } from 'lodash';
import { FC } from 'react';
import { OptionSetOption } from '@api/optionSets/types';
import { Stack } from '@components/common';
import OptionButtonListItem from './OptionButtonListItem';

interface OptionButtonListProps {
  value?: number[];
  onChange?: (value?: number[]) => void;
  options: OptionSetOption[];
  minimumSelected?: number;
  maximumSelected?: number;
}

const OptionButtonList: FC<OptionButtonListProps> = (props) => {
  const { options = [], value = [], onChange } = props;

  const maximumSelected = props?.maximumSelected || options?.length;

  /**
   * if the pressed option is already inside the value, it will be removed from the value
   * if the selected is not yet inside the value, will check the maximum selected length
   * it will remove the first element of value if exceed the maximum selected length
   * it will append the pressed option into the value tail if not exceed the maximum length yet
   *
   * @param {number} optionValue value of the pressed option
   * @returns
   */
  const handleOnPressOption = (optionValue: number) => () => {
    if (value?.includes(optionValue)) {
      return onChange?.(value?.filter((id) => id !== optionValue));
    }

    if (!value?.includes(optionValue) && value?.length < maximumSelected) {
      return onChange?.([...value, optionValue]);
    }

    return onChange?.([...value.slice(1), optionValue]);
  };

  const sortedOptions = sortBy(options, (option) => option.sequence);

  return (
    <Stack direction={'column'} flexWrap={'wrap'}>
      {sortedOptions?.map(({ id: optionValue, productName, price, outOfStockFlag }, index) => (
        <OptionButtonListItem
          onPress={handleOnPressOption(optionValue)}
          key={index}
          isSelected={value.includes(optionValue)}
          label={productName}
          description={price}
          outOfStockFlag={outOfStockFlag}
        />
      ))}
    </Stack>
  );
};

export default OptionButtonList;
