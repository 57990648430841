import { useMemo } from 'react';
import { getBrandUrl } from '@utils/navigator';

const checkWhitelistedBrandUrls = (brandUrl: string, concatenatedBrandUrls?: string) => {
  const brandUrls = concatenatedBrandUrls?.split(',') ?? [];
  if (brandUrls.includes('all')) {
    return true;
  }

  if (!brandUrl) {
    return false;
  }

  return brandUrls.includes(brandUrl);
};

const useFeatureToggle = () => {
  const brandUrl = getBrandUrl();

  return useMemo(
    () => ({
      /**
       * `true` if users should be redirected to the new online ordering site
       */
      REDIRECT_TO_NEW_SITE: checkWhitelistedBrandUrls(
        brandUrl,
        process.env.NEXT_PUBLIC_FEATURE_TOGGLE_REDIRECT_TO_NEW_SITE
      ),
    }),
    [brandUrl]
  );
};

export default useFeatureToggle;
