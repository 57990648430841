import Decimal from 'decimal.js';
import { sumBy } from 'lodash';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InView from 'react-intersection-observer';
import { Button, Stack, Typography } from '@components/common';
import { ArrowIcon, ChatFilledIcon, PhoneIcon } from '@components/icons';
import SwipeUpIcon from '@components/icons/SwipeUpIcon';
import config from '@constants/config';
import { ORDER_DETAIL_REFETCH_INTERVAL_OPTIONS, ORDER_FINAL_STATUS_LIST } from '@constants/order';
import routes from '@constants/routes';
import Token from '@constants/token';
import { usePersistentQueryParams } from '@hooks/common';
import { useCheckDineIn } from '@hooks/dineIn';
import { useRedirectByLocationSchedule } from '@hooks/locations';
import { useCommonStore } from '@hooks/storage';
import { CardActionArea, Divider } from '@mui/material';
import { useQueryBrandInfo } from '@queries/commons';
import { useQueryOrderDetail } from '@queries/order';
import { useQueryProducts } from '@queries/products';
import cartStore from '@storage/cartStore';
import { calcDecimal, formatCurrency, toNumber } from '@utils/number';
import { getCartFromOrderDetail, getProductListFromOrderDetailResponse, productQueryParams } from '@utils/product';
import CountdownTimer from './CountdownTimer';
import OrderStatusStepper from './OrderStatusStepper';
import PickupCodeDialog from './PickupCodeDialog';
import ProductRow from './ProductRow';

interface Props {
  onChangeContentHeight?: (height: number) => void;
}
const OrderTrackingDetailContent: React.FC<Props> = ({ onChangeContentHeight }) => {
  const { t } = useTranslation();

  const { storageState, updateStorage } = useCommonStore();

  const { data: brandData } = useQueryBrandInfo();
  const { query, push } = useRouter();
  const { isClosedBill, isOpenBill } = useCheckDineIn();
  const { query: persistentQueryParams } = usePersistentQueryParams();
  const [showSwipeUpLabel, setShowSwipeUpLabel] = useState(false);
  const [showPickupCode, setShowPickupCode] = useState(false);
  const mainVisibleContentRef = useRef<HTMLDivElement>(null);

  const orderId = query?.orderId as string;
  const { data } = useQueryOrderDetail(orderId, ORDER_DETAIL_REFETCH_INTERVAL_OPTIONS);

  const orderLocation = data?.location;
  const orderLocationId = orderLocation?.id || 0;

  const productParams = useMemo(() => productQueryParams(data), [data]);

  const { queryKeys } = useQueryProducts(productParams, {
    enabled: !!data?.id,
  });

  const { isOpen } = useRedirectByLocationSchedule({ locationId: orderLocationId });

  const reorderedProductList = useMemo(() => getProductListFromOrderDetailResponse(data), [data]);

  const type = data?.type || 'delivery';
  const status = data?.status || 'unpaid';

  const handlePressBack = () => {
    push({
      pathname: routes.ORDER_LIST,
      query: isClosedBill ? persistentQueryParams : {},
    });
  };

  const typeOrder = useMemo(() => {
    if (isOpenBill && !isClosedBill) {
      return 'open_bill';
    }

    if (!isOpenBill && isClosedBill) {
      return 'close_bill';
    }

    return 'online_ordering';
  }, [isClosedBill, isOpenBill]);

  /**
   * This Method is to put all item on this detail to cart item
   * if the product is already exist on cartItem and has optionSets, it will generate new customItem
   * if the product is non optionset item, it will simply add it on the branchCart
   */
  const handleReorder = useCallback(async () => {
    await cartStore.setItem(orderLocationId, getCartFromOrderDetail(reorderedProductList, queryKeys), typeOrder);
    if (data?.location?.id) {
      await updateStorage({ activeLocation: data?.location });
    }

    push(routes.PRODUCT_CART);
  }, [orderLocationId, reorderedProductList, queryKeys, typeOrder, data?.location, push, updateStorage]);

  const isOnPaymentProcessOrRestaurantConfirmation = useMemo(() => {
    return status === 'unpaid' || status === 'paid' || status === 'waiting_restaurant_confirmation';
  }, [status]);

  const orderStatusText = useMemo(() => {
    if (isOnPaymentProcessOrRestaurantConfirmation) {
      return t('order.waiting_for_restaurant_accept_label');
    }

    if (status === 'restaurant_confirmed' || status === 'waiting_food_to_be_ready') {
      return t('order.order_being_processed_label');
    }

    if (status === 'waiting_pickup') {
      return t('order.order_ready_for_pickup_label');
    }

    if (status === 'waiting_driver_to_pickup') {
      return t('order.waiting_driver_to_pickup_label');
    }

    if (status === 'delivery_processing') {
      return t('order.order_being_delivered_label');
    }

    if (status === 'refunded') {
      return t('order.order_refunded_label');
    }

    if (status === 'expired') {
      return t('order.order_expired_label');
    }

    if (status === 'cancelled') {
      return t('order.order_cancelled_label');
    }

    return t('order.order_complete_label');
  }, [isOnPaymentProcessOrRestaurantConfirmation, status, t]);

  useEffect(() => {
    const stackHeight = mainVisibleContentRef.current?.getBoundingClientRect()?.height || 0;
    if (stackHeight) {
      // 20 is drawer bleeding, Token.spacing.m * 2 is padding
      const mainVisibleContentHeight = stackHeight + 20 + Token.spacing.m * 2;
      onChangeContentHeight?.(mainVisibleContentHeight);
    }
  }, [onChangeContentHeight, data]);

  useEffect(() => {
    if (data?.status === 'expired' || data?.status === 'completed') {
      push(`${routes.ORDER_DETAIL}?orderId=${data?.id}`);
      return;
    }

    if (data?.status === 'waiting_payment') {
      push(`${routes.ORDER_PLACEMENT}?orderId=${data?.id}`);
      return;
    }
  }, [data?.id, data?.status, push]);

  const productList = useMemo(() => {
    const products = data?.products || [];
    return products.map((product) => {
      const optionSets = product?.optionSets || [];
      const parsedProductPrice = calcDecimal(product.price || '0');
      const productPrice = parsedProductPrice instanceof Decimal ? parsedProductPrice : (calcDecimal('0') as Decimal);

      const productQty = product.qty || 0;
      if (optionSets.length > 0 && productPrice instanceof Decimal) {
        const optionSetsPrice = sumBy(optionSets, (optionSet) =>
          sumBy(optionSet.optionSetOptions, (optionSetOption) => toNumber(optionSetOption.price) ?? 0)
        );

        return {
          priceTotal: productPrice.add(optionSetsPrice).mul(productQty).toString(),
          ...product,
        };
      }

      return {
        priceTotal: productPrice.mul(productQty).toString(),
        ...product,
      };
    });
  }, [data?.products]);

  /* TODO: Implement Call/Chat Driver Action */
  // const mainActionCallButtonLabel =
  //   type === 'self_pickup' || isOnPaymentProcessOrRestaurantConfirmation
  //     ? t('order.call_restaurant_label')
  //     : t('order.call_driver_label');
  // const mainActionChatButtonLabel =
  //   type === 'self_pickup' || isOnPaymentProcessOrRestaurantConfirmation
  //     ? t('order.chat_restaurant_label')
  //     : t('order.chat_driver_label');

  const totalAmountString = formatCurrency(data?.totalAmount, storageState.currency);

  const handleCallRestaurant = () => {
    if (orderLocation?.contactNumberCall) {
      window.open(`tel:${orderLocation.contactNumberCall}`);
    }
  };

  const handleChatRestaurant = () => {
    if (orderLocation?.contactNumberChat) {
      window.open(`https://wa.me/${orderLocation.contactNumberChat}`);
    }
  };

  const handleOpenPickupCode = () => {
    setShowPickupCode(true);
  };

  const handleClosePickupCode = () => {
    setShowPickupCode(false);
  };

  const renderContent = () => {
    if (status === 'refunded' || status === 'expired' || status === 'cancelled') {
      return (
        <Stack spacing={'xxl'} overflow={'hidden'} flexWrap={'wrap'}>
          {status === 'refunded' && (
            <Stack spacing={'xs'}>
              <Typography size={'s'} textAlign={'center'} color={'uiDarkSecondary'}>
                <Trans
                  i18nKey={'order.order_total_refunded_description'}
                  values={{
                    total: totalAmountString,
                  }}
                  components={[
                    <Typography
                      key={totalAmountString}
                      size={'s'}
                      textOverflow={'ellipsis'}
                      display={'inline'}
                      color={'uiPrimaryMain'}
                    />,
                  ]}
                />
              </Typography>

              <Typography textAlign={'center'} color={'uiDarkSecondary'} size={'s'}>
                <Trans
                  i18nKey={'order.order_total_refunded_contact'}
                  values={{
                    email: config.contact.email,
                  }}
                  components={[
                    <Typography
                      key={config.contact.email}
                      component={'span'}
                      display={'inline'}
                      textOverflow={'ellipsis'}
                      size={'s'}
                      color={'uiPrimaryMain'}
                    />,
                  ]}
                />
              </Typography>
            </Stack>
          )}

          <Stack flexGrow={1}>
            <Button
              onClick={handleReorder}
              disabled={!isOpen}
              variant={'contained'}
              padding={Token.spacing.m}
              color={'secondary'}
            >
              <Typography variant={'medium'}>{t('order.reorder_button_label')}</Typography>
            </Button>
          </Stack>
        </Stack>
      );
    }

    return (
      <>
        {/* TODO: Implement Call/Chat Driver Action */}
        {/* <Stack spacing={'hs'} direction={'row'} alignItems={'center'} justifyContent={'center'}>
          <Button
            fullWidth
            height={56}
            variant={'contained'}
            padding={Token.spacing.m}
            paddingLeft={Token.spacing.xs}
            paddingRight={Token.spacing.xs}
          >
            <Stack spacing={'s'} direction={'row'} alignItems={'center'}>
              <PhoneIcon color={'uiPrimaryMain'} />
              <Typography variant={'medium'}>{mainActionCallButtonLabel}</Typography>
            </Stack>
          </Button>

          <Button
            fullWidth
            height={56}
            variant={'contained'}
            padding={Token.spacing.m}
            paddingLeft={Token.spacing.xs}
            paddingRight={Token.spacing.xs}
            color={'secondary'}
          >
            <Stack spacing={'s'} direction={'row'} alignItems={'center'}>
              <ChatFilledIcon width={22} height={22} color={'uiSecondaryMain'} />
              <Typography flexGrow={1} variant={'medium'}>
                {mainActionChatButtonLabel}
              </Typography>
            </Stack>
          </Button>
        </Stack> */}
        {!showSwipeUpLabel && !ORDER_FINAL_STATUS_LIST.includes(status) && (
          <Stack direction={'row'} spacing={'xs'} alignItems={'center'} justifyContent={'center'}>
            <SwipeUpIcon />
            <Typography>
              {status === 'waiting_restaurant_confirmation'
                ? t('order.swipe_to_see_order_detail_label')
                : t('order.swipe_to_talk_restaurant_label')}
            </Typography>
          </Stack>
        )}
      </>
    );
  };

  return (
    <Stack background={'uiLightPrimary'} padding={'m'} spacing={'xxxl'}>
      <Stack spacing={'xl'} ref={mainVisibleContentRef}>
        <Stack direction={'row'} alignItems={'center'} spacing={'m'}>
          <Button onClick={handlePressBack} variant={'text'} padding={0}>
            <ArrowIcon />
          </Button>
          <Stack flexGrow={1} alignItems={'center'} justifyContent={'center'}>
            <Typography variant={'medium'}>{t('order.order_number_label', { orderId })}</Typography>
          </Stack>
        </Stack>
        <OrderStatusStepper status={status} type={type} />
        <Stack height={60} alignItems={'center'} justifyContent={'center'}>
          <Typography textAlign={'center'} size={'m'} variant={'bold'}>
            {orderStatusText}
          </Typography>
          {status === 'waiting_restaurant_confirmation' && <CountdownTimer status={status} paidAt={data?.paidAt} />}
        </Stack>
        {data?.status === 'waiting_pickup' && data?.pickupCode && (
          <Stack flexGrow={1}>
            <Button onClick={handleOpenPickupCode} variant={'contained'} padding={Token.spacing.m} color={'primary'}>
              <Typography variant={'medium'}>{t('order.pickup_code')}</Typography>
            </Button>
          </Stack>
        )}
        {renderContent()}
      </Stack>

      {!ORDER_FINAL_STATUS_LIST.includes(status) && (
        <React.Fragment>
          {type === 'delivery' && (
            <React.Fragment>
              {!isOnPaymentProcessOrRestaurantConfirmation &&
                (orderLocation?.contactNumberCall || orderLocation?.contactNumberChat) && (
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                    {orderLocation?.contactNumberCall && (
                      <CardActionArea onClick={handleCallRestaurant}>
                        <Stack paddingY={'hs'} spacing={'s'} alignItems={'center'}>
                          <PhoneIcon />
                          <Typography color={'uiDarkSecondary'} variant={'medium'}>
                            {t('order.call_restaurant_label')}
                          </Typography>
                        </Stack>
                      </CardActionArea>
                    )}

                    {orderLocation?.contactNumberChat && (
                      <CardActionArea onClick={handleChatRestaurant}>
                        <Stack paddingY={'hs'} spacing={'s'} alignItems={'center'}>
                          <ChatFilledIcon width={22} height={22} color={'uiDarkPrimary'} />
                          <Typography color={'uiDarkSecondary'} variant={'medium'}>
                            {t('order.chat_restaurant_label')}
                          </Typography>
                        </Stack>
                      </CardActionArea>
                    )}
                  </Stack>
                )}

              <Stack spacing={'xxl'}>
                <Typography color={'uiDarkSecondary'} size={'hs'}>
                  {t('order.delivery_address_label')}
                </Typography>
                <Stack direction={'row'} spacing={'hs'} alignItems={'center'}>
                  <Stack
                    width={14}
                    height={14}
                    borderWidth={'bold'}
                    borderColor={'uiRedPrimary'}
                    borderRadius={'rounded'}
                  />
                  <Stack flex={1} overflow={'hidden'}>
                    <Typography size={'hm'} variant={'bold'} noWrap textOverflow={'ellipsis'}>
                      {data?.deliveryDetail?.customerAddress?.addressName}
                    </Typography>
                    <Typography size={'hs'} noWrap textOverflow={'ellipsis'}>
                      {data?.deliveryDetail?.customerAddress?.address}
                    </Typography>
                  </Stack>
                </Stack>
                <Divider />
              </Stack>
            </React.Fragment>
          )}

          <InView trackVisibility delay={100} onChange={setShowSwipeUpLabel}>
            <Stack spacing={'m'}>
              <Typography color={'uiDarkSecondary'} size={'hs'}>
                {t('order.order_detail_label')}
              </Typography>
              {productList.map((product) => (
                <ProductRow product={product} key={product.id} brandData={brandData} />
              ))}
            </Stack>
          </InView>
        </React.Fragment>
      )}
      <PickupCodeDialog pickupCode={data?.pickupCode} open={showPickupCode} onClose={handleClosePickupCode} />
    </Stack>
  );
};

export default OrderTrackingDetailContent;
