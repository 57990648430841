import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DecimalNumber } from '@api/baseAPI/types';
import { useCommonStore } from '@hooks/storage';
import { calcDecimal, formatCurrency, toLocaleStringNumber } from '@utils/number';

const useProductPrice = (price?: DecimalNumber | number | 'N/A') => {
  const { t } = useTranslation();
  const { storageState } = useCommonStore();

  return useMemo(() => {
    if (price === 'N/A') {
      return {
        label: price,
        isFree: false,
        isNegative: false,
      };
    }

    const priceDecimal = calcDecimal(price);
    if (priceDecimal instanceof Decimal) {
      if (priceDecimal.equals(0)) {
        return {
          label: t('products.free'),
          isFree: true,
          isNegative: false,
        };
      }

      if (priceDecimal.lessThan(0)) {
        return {
          label: toLocaleStringNumber(`${price}`),
          isFree: false,
          isNegative: true,
        };
      }

      return {
        label: formatCurrency(priceDecimal, storageState.currency),
        isFree: false,
        isNegative: false,
      };
    }

    return {
      label: toLocaleStringNumber(`${price}`),
      isFree: false,
      isNegative: false,
    };
  }, [price, storageState.currency, t]);
};

export default useProductPrice;
